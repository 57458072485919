import React, { useState, useEffect, useMemo } from "react";

import { 
    defineActionsTimelineColor as defineColor, 
    defineActionsTimelineIcon as defineIcon,
    isNonEmptyArray
} from "_helpers";

import { Card, Col, Image, Row, Timeline, Tooltip } from "antd";

import parse from 'html-react-parser';
import dayjs from 'dayjs';

import styles from "_styles/FolderDetail.module.css";

import { ReactComponent as NextAction } from "_assets/folderDetail/actions/next-action.svg";

export function Actions({ fetchData }){
    const [ itemsActions, setItemsActions ] = useState([]);

    // const formatFR = useMemo(() => ( 'DD/MM/YY HH:mm:ss' ), []);
    const formatFR = useMemo(() => ( 'DD/MM/YY' ), []);

    useEffect(() => {
        let tempData = [], i = 0;
        const pattern = /<b><\/b>/;
        let wrongCase = null;

        if( fetchData.length > 1 ){
            const firstDate = fetchData[0]['_date_sched'];
            const secondDate = fetchData[1]['date_actual'] ? fetchData[1]['date_actual'] : fetchData[1]['_date_sched'];

            if( dayjs(firstDate).isBefore(dayjs(secondDate)) ){
                wrongCase = true;
            }
        }

        fetchData
            .filter((value, index, self) => ( // Remove duplicates actions
                index === self.findIndex(t => (
                    t.link_status === value.link_status &&
                    t.link_action === value.link_action &&
                    t.date_actual === value.date_actual &&
                    t.txt_short === value.txt_short
                ))
            ))
            .forEach((data, index) => {
                const { link_status, link_action, date_actual, txt_short, txt, _status_is_ok, _date_sched, _is_nextAction, _file_is_closed } = data;

                if( !_status_is_ok && !_is_nextAction ) return ;
                if( _is_nextAction && index !== 0 && pattern.test(txt_short) ) return ;
                if( wrongCase && index === 0 ) return ;

                tempData.push({
                    color: defineColor(link_status),
                    children: (
                        <>
                            <Row className="align-items-baseline">
                                <Col flex="20px" className="me-2">
                                    <Image src={defineIcon(link_action)} preview={false} height={20} width={20}/>
                                </Col>
                                {
                                    _is_nextAction && !_file_is_closed && i === 0
                                    &&
                                        <Col flex="20px" className="me-2">
                                            <Tooltip title="prochaine action">
                                                <NextAction height={20} width={20}/>
                                            </Tooltip>
                                        </Col>
                                }
                                <Col xs={( _is_nextAction && !_file_is_closed && i === 0) ? 20 : 21}>
                                    { 
                                        date_actual
                                        ? 
                                            dayjs(date_actual).format(formatFR) 
                                        : 
                                            dayjs(_date_sched).format(formatFR) 
                                    } 
                                    {
                                        !pattern.test(txt_short)
                                        &&
                                            <>
                                                &nbsp;- {parse(txt_short)}
                                            </>
                                    }
                                </Col>
                            </Row>
                            {
                                txt &&
                                <Row className="align-items-baseline">
                                    { txt }
                                </Row>
                            }
                        </>
                    )
                })

                i++;
        })

        setItemsActions(tempData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData])

    return (
        <>
            {
                isNonEmptyArray(itemsActions)
                &&
                    <div className="h-100">
                        <Card className="h-100" bodyStyle={{height: '100%'}}>
                            <div className={styles.actionsTitlePanel}>{('Historique des actions').toUpperCase()}</div>
                            <Timeline className="mt-2 pt-2" style={{height: 'calc(100% - 30px)', overflowY: 'auto'}} items={itemsActions} />
                        </Card>
                    </div>
            }
        </>
    );
}