import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";

import { CustomConversationBubble as ConversationBubble, CustomTextarea as TextArea } from "_components";
import { useInvoices } from "_contexts";
import { isNonEmptyArray, isNonEmptyObject, formatToCapitalize, shallowCopy, formatWithDecimals, defineImgSrcToDownload as defineSrc } from "_helpers";
import { useFolderDetailGetMessages, useFolderDetailGetMessageAttachment, useFolderDetailSendMessage, useTextarea } from "_hooks";

import { 
    Button, 
    Card, 
    Col, 
    Empty, 
    Image, 
    message, 
    Modal, 
    Progress, 
    Row, 
    Space, 
    Tag, 
    Tooltip 
} from "antd";

import { ReactComponent as Send } from "_assets/folderDetail/invoices/messages/send.svg";
import { ReactComponent as CloseModal } from "_assets/close-window.svg";

import styles from "_styles/Components/_Modal.module.css";

import dayjs from "dayjs";

export const CustomModal = ({ payload, summary, modalHandler }) => {
    const [ messages, setMessages ] = useState({});

    const [ idToRemove, setIdToRemove ] = useState(null);

    const maxSizeAllowed = useMemo(() => 2, [])
    const [ totalSizeToUpload, setTotalSizeToUpload ] = useState(0);
    const [ totalProgressParams, setTotalProgressParams ] = useState({
        percent: 0,
        status: undefined
    });

    const [ rowChatWidth, setRowChatWidth ] = useState(0);

    const { modalIsOpen, setModalIsOpen } = modalHandler;

    const { fetchMessages, doReload } = useFolderDetailGetMessages( modalIsOpen, payload );
    const { setBinId } = useFolderDetailGetMessageAttachment();
    const { textareaValuesHandler, textareaResetValues, setSubmit, successMessage, errorMessage } = useFolderDetailSendMessage( payload );

    const { setLitigMessages } = useInvoices();

    const { values, setValues } = textareaValuesHandler;

    const messagesBoxRef = useRef();
    const textareaRef = useRef();
    const rowChatRef = useRef();
    const textareaHeight = useTextarea(textareaRef.current, values.text);

    const [ messageApi, contextHolder ] = message.useMessage();

    const success = useCallback(( message ) => {
        messageApi.success(message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [])

    const error = useCallback(( message, duration = 1.5 ) => {
        messageApi.error(message, duration);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [])

    useEffect(() => {
        if( successMessage || errorMessage ){
            if( successMessage ){
                success(successMessage);
            } else {
                error(errorMessage);
            }
            textareaResetValues();
            setSubmit(false);
            doReload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMessage, errorMessage])

    useEffect(() => {
        messagesOrdering();
        setLitigMessages(fetchMessages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMessages])

    useEffect(() => {
        if( modalIsOpen && messagesBoxRef.current ){
            const messagesBox = messagesBoxRef.current;

            messagesBox.scrollTo(0, messagesBox.scrollHeight);
        }
        if( !modalIsOpen ){
            textareaResetValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalIsOpen, messagesBoxRef.current])

    useEffect(() => {
        if( isNonEmptyArray(values.attachments) ){
            const attachments = values.attachments;
    
            const totalSizeInOctets = attachments.reduce((acc, val) => acc + val.size, 0);
            const totalSizeInMegaOctets = formatWithDecimals((totalSizeInOctets / 1000000), 1);
    
            setTotalSizeToUpload(totalSizeInMegaOctets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.attachments])

    useEffect(() => {
        let status;

        if( totalSizeToUpload > maxSizeAllowed ){
            status = 'exception';
        } else if( totalSizeToUpload < maxSizeAllowed ){
            status = 'active';
        } else if( totalSizeToUpload === maxSizeAllowed ){
            status = 'success';
        }

        setTotalProgressParams({
            percent: totalSizeToUpload/maxSizeAllowed * 100,
            status
        })
    }, [totalSizeToUpload, maxSizeAllowed])

    useEffect(() => {
        if( values.attachments ){
            const attachments = values.attachments;
            const found = attachments.find(el => el.uid === idToRemove);
    
            if( idToRemove && found ){
                handleRemovePjItem(idToRemove);
                setIdToRemove(null);
                error('Ce type de pièce n\'est pas pris en charge (uniquement .jpg, .png, .pdf, .xls, .xlsx)', 6);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.attachments, idToRemove])

    useEffect(() => {
        if( rowChatRef?.current && rowChatRef.current.clientWidth ){
            setRowChatWidth(rowChatRef.current.clientWidth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowChatRef?.current, messages])

    const messagesOrdering = useCallback(() => {
        if( isNonEmptyArray(fetchMessages) ){
            const reduced = fetchMessages.reduce((acc, val) => {
                const { msg_direction, msg_date, msg_txt, ...rest } = val;
    
                const time = dayjs(msg_date).format('HH:mm');
                const fullDay = dayjs(msg_date).format('DD/MM/YYYY');
                const day = formatToCapitalize(dayjs(msg_date).format('dddd'));
    
                const difference = dayjs().diff(dayjs(msg_date).startOf('day'), 'day');
                let key;
                if( difference === 0 ){
                    key = 'Aujourd\'hui';
                } else if( difference === 1 ){
                    key = 'Hier';
                } else if( difference === 2 ){
                    key = 'Avant-Hier';
                } else if( difference > 2 && difference < 7 ){
                    key = day;
                } else {
                    key = fullDay;
                }

                if( !acc[key] ){
                    acc[key] = [];
                }
    
                acc[key].push({
                    msg_direction,
                    msg_time: time,
                    msg_txt,
                    ...rest
                })

                return acc;
            }, {});

            const reeorder = Object.fromEntries(Object.entries(reduced)
                .sort((a,b) => {
                    const aa = a[0];
                    const bb = b[0];

                    const daysOrder = [
                        'Avant-Hier', 
                        'Hier', 
                        'Aujourd\'hui'
                    ];

                    const aaDayIndex = daysOrder.indexOf( aa );
                    const bbDayIndex = daysOrder.indexOf( bb );

                    if( aaDayIndex === -1 && bbDayIndex === -1 ){
                        if( dayjs(aa).isBefore(dayjs(bb)) ){
                            return -1;
                        }
                        return 1;
                    }

                    if ( aaDayIndex === bbDayIndex ){
                        return 0;
                    } else if( aaDayIndex > bbDayIndex ){
                        return 1;
                    }

                    return -1;
                })
            )
    
            setMessages(reeorder);
        }
    }, [fetchMessages])

    const handleRemovePjItem = ( id ) => {
        let attachmentsCopy = shallowCopy(values.attachments);
        attachmentsCopy = attachmentsCopy.filter(l => l.uid !== id);

        setValues(oldVal => ({ ...oldVal, attachments: attachmentsCopy }));
    }

    const handleSubmitMessage = () => {
        if( modalIsOpen && values.text ){
            setSubmit(true);
        }
    }

    return (
        <>
            { contextHolder }
            <Modal 
                open={modalIsOpen}
                width={600}
                height={770}
                footer={null}
                closeIcon={null}
                closable={false}
                // mask={false}
                destroyOnClose={true}
                className={styles.modalContent}
            >
                <Row style={{ height: '30px' }}>
                    <div className="ms-auto" style={{ cursor: 'pointer' }} onClick={() => setModalIsOpen(oldVal => !oldVal)}>
                        <CloseModal height={28} width={28} />
                    </div>
                </Row>
                {
                    summary &&
                    <Card 
                        className="mt-2" 
                        style={{ height: '200px', overflow: 'auto' }}
                    >
                        <span style={{ whiteSpace: 'pre-line' }}>
                            { summary }
                        </span>
                    </Card>
                }
                <Card 
                    ref={messagesBoxRef}
                    className="mt-2" 
                    style={{ maxHeight: '270px', overflow: 'auto' }}
                >
                    {
                        isNonEmptyObject(messages)
                        ?
                            <>
                                {
                                    Object.entries(messages)
                                        .map(([key, val]) => {
                                            return (
                                                <React.Fragment key={key}>
                                                    <Row className="justify-content-center">
                                                        <Tag color="#9c9a97">
                                                            { key }
                                                        </Tag>
                                                    </Row>
                                                    {
                                                        val.map((v,i) => {
                                                            const { msg_direction, msg_time, msg_txt, attachments } = v;

                                                            return (
                                                                <Row key={i} ref={rowChatRef}>
                                                                    <Col xs={24} className={['d-flex', msg_direction === 'out' ? 'justify-content-end' : ''].join(" ")}>
                                                                        <ConversationBubble 
                                                                            direction={ i === 0 ? msg_direction : undefined } 
                                                                            bubbleColor={ msg_direction === 'out' ? '#0b6fba' : undefined}
                                                                        >
                                                                            { msg_txt }
                                                                        </ConversationBubble>
                                                                    </Col>
                                                                    <Col 
                                                                        style={{ 
                                                                            width: '280px', 
                                                                            marginLeft: msg_direction === 'out' && rowChatWidth ? (rowChatWidth - 280) + 'px' : undefined
                                                                        }}
                                                                    >
                                                                        {
                                                                            attachments
                                                                            &&
                                                                                attachments.map((attach, idx) => {
                                                                                    const { bin_id, bin_name, bin_type } = attach;
                                                                                    const sliceTo = 32;
                                                                                    let bin_name_sliced;
                                                                                    if( bin_name.length > sliceTo ){
                                                                                        bin_name_sliced = bin_name.slice(0, sliceTo) + '...';
                                                                                    }
                                                                                    return (
                                                                                        <div 
                                                                                            key={idx} 
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={() => setBinId(bin_id)}
                                                                                        >
                                                                                            {
                                                                                                bin_name_sliced
                                                                                                ?
                                                                                                    <Tooltip title={bin_name} overlayStyle={{ maxWidth: '500px' }}>
                                                                                                        <Button 
                                                                                                            type="text" 
                                                                                                            icon={
                                                                                                                <Image 
                                                                                                                    src={defineSrc(bin_type)} 
                                                                                                                    preview={false} 
                                                                                                                    height={22} 
                                                                                                                />
                                                                                                            }
                                                                                                            styles={{
                                                                                                                icon: {
                                                                                                                    marginRight: 0
                                                                                                                }
                                                                                                            }}
                                                                                                            className="p-0 d-flex align-items-center"
                                                                                                        >
                                                                                                            { bin_name_sliced }
                                                                                                        </Button>
                                                                                                    </Tooltip>
                                                                                                :
                                                                                                    <Button 
                                                                                                        type="text" 
                                                                                                        icon={
                                                                                                            <Image 
                                                                                                                src={defineSrc(bin_type)} 
                                                                                                                preview={false} 
                                                                                                                height={22} 
                                                                                                            />
                                                                                                        }
                                                                                                        styles={{
                                                                                                            icon: {
                                                                                                                marginRight: 0
                                                                                                            }
                                                                                                        }}
                                                                                                        className="p-0 d-flex align-items-center"
                                                                                                    >
                                                                                                        { bin_name }
                                                                                                    </Button>
                                                                                            }
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                        }
                                                                    </Col>
                                                                    <Col 
                                                                        style={{ 
                                                                            width: '280px', 
                                                                            marginLeft: msg_direction === 'out' && rowChatWidth ? (rowChatWidth - 280) + 'px' : undefined
                                                                        }}
                                                                    >
                                                                        <span className="d-flex justify-content-end">{ msg_time }</span>
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        })
                                                    }
                                                </React.Fragment>
                                            );
                                    })
                                }
                            </>
                        :
                            <Empty />
                    }
                </Card>
                <Card 
                    bordered={false} 
                    style={{ 
                        boxShadow: 'none', 
                        height: '200px', 
                        maxHeight: '235px'
                    }} 
                    bodyStyle={{ 
                        padding: 0, 
                        height: '100%' 
                    }}
                >
                    <Row gutter={4} className='mt-2' style={{ height: textareaHeight, maxHeight: isNonEmptyArray(values.attachments) ? '125px' : '230px' }}>
                        <Col xs={22} className="h-100 d-flex">
                            <TextArea 
                                textareaRef={textareaRef}
                                textareaHandler={textareaValuesHandler}
                                textareaHeight={textareaHeight}
                                setIdToRemove={setIdToRemove}
                                pj
                            />    
                        </Col>
                        <Col xs={2} className="h-100 d-flex">
                            <Button 
                                type="primary" 
                                className={["w-100", textareaHeight > 32 ? "mt-auto" : undefined].join(" ")} 
                                icon={<Send height={24} width={24} />} 
                                onClick={handleSubmitMessage}
                            />
                        </Col>
                    </Row>
                    {
                        isNonEmptyArray(values.attachments)
                        &&
                            <Row
                                style={{ height: '110px' }}
                            >
                                <Space 
                                    className="mt-2 w-100" 
                                    direction="vertical"
                                    style={{ maxHeight: '72px', overflow: 'auto' }}
                                >
                                    {
                                        values.attachments.map((item) => {
                                            const { uid, name, percent, status } = item;

                                            return (
                                                <div className="d-flex align-items-center">
                                                    <Progress 
                                                        type="circle" 
                                                        percent={percent} 
                                                        strokeWidth={20}
                                                        size={18}
                                                        status={() => {
                                                            switch( status ){
                                                                case 'uploading' : return 'active' ;
                                                                case 'done' : return 'success' ;
                                                                case 'error' : return 'exception' ;
                                                                case 'success' :
                                                                case 'removed' :
                                                                default : break ;
                                                            }
                                                        }}
                                                        format={(p) => p + '%'}
                                                        className="me-1"
                                                    />
                                                    <Tag 
                                                        key={uid} 
                                                        closable
                                                        onClose={() => handleRemovePjItem(uid)}
                                                        style={{ width: 'inherit' }}
                                                    >
                                                        {name}
                                                    </Tag>
                                                </div>
                                            )
                                        })
                                    }
                                </Space>
                                <Progress 
                                    percent={totalProgressParams.percent}
                                    status={totalProgressParams.status}
                                    format={() => totalSizeToUpload + '/' + maxSizeAllowed + ' Mo'}
                                    style={{ paddingRight: '35px', marginRight: '0px' }}
                                />
                            </Row>
                    }
                </Card>
            </Modal>
        </>
    );
}