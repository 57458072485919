import { 
    formatTextReplaceAccents as formatText,
    compareTextContains as textContains, 
    isNonEmptyArray
} from "_helpers";

import { Tag } from "antd";

const defaultColDef = {
    sortable: true,
    resizable: true,
    type: 'centerAlign',
    pinned: null,
    filter: 'agMultiColumnFilter',
    filterParams: {
        filters: [
            {
                //   filter: 'agTextColumnFilter',
                filterParams: { 
                    // defaultOption: 'startsWith',
                    // closeOnApply: true, // only work if apply button
                    trimInput: true,
                    filterOptions: ['contains', 'notContains'],
                    textCustomComparator: textContains,
                    textFormatter: formatText,
                    buttons: ['reset']
                }
            },
            {
                filter: 'agSetColumnFilter', 
                filterParams: {
                    textFormatter: formatText,
                    buttons: ['reset']
                }
            }
        ]
    },
    cellClassRules: {
        bold: params => params.node.rowPinned
    },
    getQuickFilterText: function(params) {
        return params.colDef.hide ? '' : params.value; 
    },
    menuTabs: ['filterMenuTab', 'columnsMenuTab']
};

export const createPinData = ( rowData ) => {
    let result = {
        inv_nb: 0, 
        inv_sumAmount: 0, 
        inv_sumRest: 0
    };

    if( isNonEmptyArray(rowData) ){
        rowData.forEach(data => {
            result["inv_nb"] += data.inv_nb;
            result["inv_sumAmount"] += data.inv_sumAmount;
            result["inv_sumRest"] += data.inv_sumRest;
        })
    }

    return [result];
}

export const columnDefs = [
    { headerName: "Entité", field: "soc_txt" },
    { headerName: "N° Client", field: "acc_id", width: 250 },
    { headerName: "Nom", field: "acc_txt", width: 250 },
    { headerName: "Statut", field: "status_txt", width: 120, cellRenderer: ({ node }) => {
        const { data, rowPinned } = node;

        if( !rowPinned ){
            return (
                <div className="d-flex justify-content-center">
                    <Tag color={data.status_color}>{data.status_txt}</Tag>
                </div>
            );
        }
    }},
    { headerName: "Etat", field: "communication_state", width: 180, type: "stateColumn" },
    { headerName: "Montant", field: "inv_sumAmount", type: "valueColumn" },
    { headerName: "Solde", field: "inv_sumRest", type: "valueColumn" }
];

export const gridOptions = {
    defaultColDef: defaultColDef,
    rowHeight: 25,
    rowSelection: 'multiple',
    suppressContextMenu: true,
    rowMultiSelectWithClick: true,
    debounceVerticalScrollbar: true,
    rowBuffer: 20,
    cacheQuickFilter: true
};