import { formatNumbers, formatWithTwoDecimals } from "_helpers";

import { CustomModalOpener as ModalOpener } from "./_ModalOpener";

import { Badge, Col, Row } from "antd";

import dayjs from "dayjs";

import { ReactComponent as Invoice } from "_assets/folderDetail/invoices/invoice-bill.svg";

export const columns = [
    {
        title: 'Dossier/Fact',
        dataIndex: 'record_ref',
        width: 350,
        render: (text, record) => {
            const { _status_color, _isLeaf, _status_id } = record;

            return (
                <>
                    {
                        !_isLeaf
                        ?
                            <Row>
                                <Col xs={1}>
                                    <Badge className="dot-8" color={_status_color}/>
                                </Col>
                                <Col xs={23}>
                                    {text}
                                    { _status_id !== 'S0_PRE' && <ModalOpener {...record}/> }
                                </Col>
                            </Row>
                        :
                            <Row>
                                <Col flex="25px">
                                    <Invoice width={22} height={22}/>
                                </Col>
                                <Col xs={21}>
                                    {text}
                                </Col>
                            </Row>
                    }
                </>
            );
        }
    },
    {
        title: 'Date',
        dataIndex: 'date_record',
        width: 150,
        align: 'center',
        render: text => {
            return text ? dayjs(text).format("DD/MM/YYYY") : undefined;
        }
    },
    {
        title: 'Montant',
        dataIndex: 'amount',
        width: 200,
        align: 'right',
        className: 'thead-center',
        render: (text, { _isLeaf }) => {
            const txt = text ? formatWithTwoDecimals(text) : null;
            return (
                <>
                    {
                        txt 
                        ? 
                            !_isLeaf
                            ?
                                <span style={{fontWeight: 'bold'}}>
                                    {formatNumbers(txt)}
                                </span>
                            :
                                <span>
                                    {formatNumbers(txt)}
                                </span>
                        : 
                            undefined
                    }
                </>
            );
        }
    },
    {
        title: 'Lettrage',
        dataIndex: 'letter_code',
        width: 200,
        className: 'thead-center',
        render: text => {
            return (
                <>
                    {
                        text
                        ?
                            <div className="d-flex flex-word justify-content-center">
                                <Badge className="mx-1 dot-8" color="green"/>
                                <span>
                                    {text}
                                </span>
                            </div>
                        :
                            undefined

                    }
                </>
            );
        }
    }
]